import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <h1>Merci</h1>
    <p>
      Votre message a bien été envoyé, nous vous contacterons dès que possible.
    </p>
  </Layout>
);

export default IndexPage;
